/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Box} from "theme-ui";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(Box, {
    sx: {
      textAlign: "left"
    }
  }, React.createElement(_components.h3, {
    id: "the-senior-minset-email-crash-course"
  }, React.createElement(_components.a, {
    href: "#the-senior-minset-email-crash-course"
  }, "The Senior Minset email crash course")), React.createElement(_components.p, null, "Get a ", React.createElement(_components.strong, null, "free chapter"), " from the Senior Engineer Mindset book and a ", React.createElement(_components.strong, null, "sample audiobook chapter"), ", followed by a Senior Mindset 101 email course."), React.createElement(_components.p, null, "You'll get insights to apply at your work right away."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
