/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Box} from "theme-ui";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h3, {
    id: "software-engineering-lessons-from-production"
  }, React.createElement(_components.a, {
    href: "#software-engineering-lessons-from-production"
  }, "Software Engineering Lessons from Production")), "\n", React.createElement(_components.p, null, "Join Swizec's Newsletter and get insightful emails 💌 on ", React.createElement(_components.strong, null, "mindsets"), ", ", React.createElement(_components.strong, null, "tactics"), ", and ", React.createElement(_components.strong, null, "technical skills"), " for your career. Real lessons from building production software. No bullshit."), "\n", React.createElement(Box, {
    sx: {
      textAlign: "left"
    }
  }, React.createElement("blockquote", null, React.createElement(_components.p, null, "\"Man, love your simple writing! Yours is the only newsletter I open and only blog that I give a fuck to read & scroll\ntill the end. And wow always take away lessons with me.\nInspiring! And very relatable. 👌\""), React.createElement(_components.p, null, "~ ", React.createElement(_components.a, {
    href: "https://twitter.com/ashfame/status/1286756578226405376"
  }, "Ashish Kumar")))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
