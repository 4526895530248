/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    p: "p",
    strong: "strong",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "become-a-true-senior-engineer"
  }, React.createElement(_components.a, {
    href: "#become-a-true-senior-engineer"
  }, "Become a *true* Senior Engineer")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Get promoted, earn a bigger salary, work for top companies")), "\n", React.createElement(_components.p, null, "Getting that senior title is easy. Just stick around. Being a ", React.createElement(_components.em, null, "true"), " senior takes a new way of thinking. Do you have it?"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
