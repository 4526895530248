/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    p: "p",
    em: "em",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "learned-something-new-want-to-become-a-react-expert"
  }, React.createElement(_components.a, {
    href: "#learned-something-new-want-to-become-a-react-expert"
  }, "Learned something new? Want to become a React expert?")), "\n", React.createElement(_components.p, null, "Learning from tutorials is easy. You follow some steps, learn a smol lesson, and feel like you got dis 💪"), "\n", React.createElement(_components.p, null, "Then comes the interview, a real world problem, or a question from the boss. Your mind goes blank. Shit, how does this work again ..."), "\n", React.createElement(_components.p, null, "Happens to everyone. Building is harder than recognizing and the real world is a mess! ", React.createElement(_components.em, null, "Nothing"), " fits in neat little boxes you learned about in tutorials."), "\n", React.createElement(_components.p, null, "That's where my emails come in – lessons from experience. Building production software :)"), "\n", React.createElement(_components.p, null, "Leave your email and ", React.createElement(_components.strong, null, "get the React email Series"), " - a series of curated essays on building with React. Borne from experience, seasoned with over 12+ years of hands-on practice building the engineering side of growing SaaS companies."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
