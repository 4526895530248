/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    p: "p",
    em: "em",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "want-to-become-a-fullstack-web-expert"
  }, React.createElement(_components.a, {
    href: "#want-to-become-a-fullstack-web-expert"
  }, "Want to become a Fullstack Web expert?")), "\n", React.createElement(_components.p, null, "Learning from tutorials is great! You follow some steps, learn a smol lesson, and feel like you got this. Then you go into an interview, get a question from the boss, or encounter a new situation and o-oh."), "\n", React.createElement(_components.p, null, "Shit, how does this work again? 😅"), "\n", React.createElement(_components.p, null, "That's the problem with tutorials. They're not how the world works. Real software is a mess. A best-effort pile of duct tape and chewing gum. ", React.createElement(_components.em, null, "You need deep understanding, not recipes"), "."), "\n", React.createElement(_components.p, null, "Leave your email and ", React.createElement(_components.strong, null, "get the Fullstack Web Essays series"), " - a series of curated essays and experiments on modern Fullstack Web development. Lessons learned from practice building production software."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
