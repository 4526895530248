/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Box} from "theme-ui";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    p: "p",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(Box, {
    sx: {
      textAlign: "left"
    }
  }, React.createElement(_components.h3, {
    id: "serverless-handbook-for-frontend-engineers-free-chapter"
  }, React.createElement(_components.a, {
    href: "#serverless-handbook-for-frontend-engineers-free-chapter"
  }, "Serverless Handbook for Frontend Engineers – free chapter")), React.createElement(_components.p, null, "Dive modern backend. Understand any backend."), React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Serverless Handbook taught me high-leveled topics. I don't like recipe courses and these chapters helped me to feel like I'm not a total noob anymore."), "\n"), React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "The hand-drawn diagrams and high-leveled descriptions gave me the feeling that I don't have any critical \"knowledge gaps\" anymore."), "\n"), React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "~ Marek C, engineer"), "\n"), React.createElement(_components.p, null, "Start with a free chapter and email crash course ❤️"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
