/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    p: "p",
    strong: "strong",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "learned-something-new-read-more-software-engineering-lessons-from-production"
  }, React.createElement(_components.a, {
    href: "#learned-something-new-read-more-software-engineering-lessons-from-production"
  }, "Learned something new? ", React.createElement("br"), "Read more Software Engineering Lessons from Production")), "\n", React.createElement(_components.p, null, "I write articles with real insight into the career and skills of a modern software engineer. ", React.createElement(_components.strong, null, React.createElement(_components.em, null, "\"Raw and honest from the heart!\"")), " as one reader described them. Fueled by ", React.createElement(_components.strong, null, "lessons learned over 20 years"), " of building ", React.createElement(_components.strong, null, "production code"), " for side-projects, small businesses, and hyper growth startups. Both successful and not."), "\n", React.createElement(_components.p, null, "Subscribe below 👇"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
