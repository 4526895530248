/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "want-to-learn-about-indie-hacking-from-the-trenches"
  }, React.createElement(_components.a, {
    href: "#want-to-learn-about-indie-hacking-from-the-trenches"
  }, "Want to learn about Indie Hacking from the trenches?")), "\n", React.createElement(_components.p, null, "I believe this is the best and worst time to launch something."), "\n", React.createElement(_components.p, null, "The best because consumers are used to paying and they hate big tech. People want your thing and they want to pay you. Not a faceless behemoth that steals their data and turns users into commodities. Blech"), "\n", React.createElement(_components.p, null, "And if you're an engineer or product manager, the swan song of easy money in tech is irresistible."), "\n", React.createElement(_components.p, null, "But you should never stop tinkering on the side. Multiple streams of income are a great way to ", React.createElement(_components.a, {
    href: "https://swizec.com/blog/how-to-recession-proof-your-career/"
  }, "recession proof your career"), "."), "\n", React.createElement(_components.p, null, "Leave your email and ", React.createElement(_components.strong, null, "get the Indie Hacking Series"), " - a series of curated essays from the trenches of Indie Hacking."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
