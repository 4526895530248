/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Box} from "theme-ui";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(Box, {
    sx: {
      textAlign: "left"
    }
  }, React.createElement(_components.h3, {
    id: "curated-fullstack-web-essays"
  }, React.createElement(_components.a, {
    href: "#curated-fullstack-web-essays"
  }, "Curated Fullstack Web Essays")), React.createElement(_components.p, null, "Get a series of curated essays on Fullstack Web development. Lessons and insights from building software for production. No bullshit."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
