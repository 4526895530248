module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"markdownCaptions":false,"maxWidth":890,"linkImagesToOriginal":false,"showCaptions":["title","alt"],"withWebp":true,"wrapperStyle":"text-align: center; font-style: italic","backgroundColor":"white","quality":50,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Course platform","short_name":"Course platform","description":"Theme for a course platform","start_url":"/","background_color":"#fff","theme_color":"#FF002B","display":"standalone","icon":"./static/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"32813dbeb138b475a5f408f511991f65"},
    },{
      plugin: require('../node_modules/@swizec/gatsby-theme-course-platform/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/typography.js","omitGoogleFont":true},
    },{
      plugin: require('../plugins/gatsby-typeform-source/gatsby-browser.js'),
      options: {"plugins":[],"token":"tfp_GTZWXeGZeGfrXuiddGWnJRbRnSSABDKZD7yX9i3dRqJA_3sp6nReWbXmTNF","formId":"jLgVKKLf"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"swizec.com","short_name":"swizec.com","description":"Swizec turns coders into high value JavaScript experts with books, articles, talks, and workshops","start_url":"/","background_color":"#fff","theme_color":"#FF002B","display":"standalone","icon":"./static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"32813dbeb138b475a5f408f511991f65"},
    },{
      plugin: require('../node_modules/@swizec/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"swizec.com"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
