/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {FooterBanner} from "../../../components/SeniorMindsetBanner";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    a: "a",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(FooterBanner), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Have a burning question that you think I can answer?"), " Hit me up on ", React.createElement(_components.a, {
    href: "https://twitter.com/swizec"
  }, "twitter"), " and I'll do my best."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Who am I and who do I help?"), " I'm Swizec Teller and I turn coders into engineers with ", React.createElement(_components.em, null, "\"Raw and honest from the heart!\""), " writing. No bullshit. Real insights into the career and skills of a modern software engineer."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Want to become a ", React.createElement(_components.em, null, "true"), " senior engineer?"), " Take ownership, have autonomy, and be a force multiplier on your team. The Senior Engineer Mindset ebook can help 👉 ", React.createElement("a", {
    href: "https://swizec.com/senior-mindset"
  }, "swizec.com/senior-mindset"), ". These are the shifts in mindset that unlocked my career."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Curious about Serverless and the modern backend?"), " Check out Serverless Handbook, for frontend engineers 👉\n", React.createElement(_components.a, {
    href: "https://serverlesshandbook.dev"
  }, "ServerlessHandbook.dev")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Want to Stop copy pasting D3 examples and create data visualizations of your own?"), " Learn how to build scalable dataviz React components your whole team can understand\nwith ", React.createElement(_components.a, {
    href: "https://reactfordataviz.com"
  }, "React for Data Visualization")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Want to get my best emails on JavaScript, React, Serverless, Fullstack Web, or Indie Hacking?"), " Check out ", React.createElement(_components.a, {
    href: "https://swizec.com/collections"
  }, "swizec.com/collections")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Did someone amazing share this letter with you?"), " Wonderful! You can sign up for my weekly letters for software engineers on their path to greatness, here: ", React.createElement(_components.a, {
    href: "https://swizec.com/blog"
  }, "swizec.com/blog")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Want to brush up on your modern JavaScript syntax?"), " Check out my interactive cheatsheet: ", React.createElement(_components.a, {
    href: "https://es6cheatsheet.com"
  }, "es6cheatsheet.com")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "By the way, just in case no one has told you it yet today: I love and appreciate you for who you are ❤️")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
